@use "sass:math";
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');

/*$background-colour: #25072C;
$menu-colour: #4A0E58;
$text-colour: white;*/
$background-colour: #f5f3f0;
$menu-colour: #edeae4;
$text-colour: black;
$text-weight: 600;
$border-colour: $text-colour;
$hover-colour: red;
$outline-thickness: 6px;
$side-margins: 20px;

/*** display breakpoints ***

phone-small : 
@media screen and (max-width: 360px)

phone-regular : 
@media screen and (min-width: 361px) and (max-width: 640px)

tablet and up : 
@media screen and (min-width: 641px)

tablet : 
@media screen and (min-width: 641px) and (max-width: 999px)

pocket : 
@media screen and (max-width: 999px)

    @media screen and (max-width: 360px) {
        
    }

    @media screen and (min-width: 361px) and (max-width: 740px) {
        
    }

    @media screen and (min-width: 741px) {
        
    }

*/

%container-for-centering {
  display: flex;
  align-items: center;
  justify-content: center;
}

%readex-pro-400 {
    font-family: "Readex Pro", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
      "HEXP" 0;
}

body {
    background-color: $background-colour;
}

#app-background {
    @extend %container-for-centering;
    width: 100vw;
    height: 100vh;
    min-height: 680px;
    min-width: 360px;

    font-family: system-ui;
    background-image: url('dot.svg');
    background-repeat: repeat;
}

#app-frame {
    @extend %container-for-centering;
    @extend %readex-pro-400;
    position: relative;
    visibility: hidden;
    overflow: hidden;
    max-height: 640px;

    @media screen and (max-width: 680px) {
        width: calc(100% - $side-margins);
        height: calc(100% - 20px);
    }

    @media screen and (min-width: 681px) and (max-width: 979px) {
        width: calc(100% - $side-margins);;
        height: calc(100% - 40px);
    }

    @media screen and (min-width: 980px) {
        width: 960px;
        height: calc(100% - 40px);
    }

}

#signature {
    position: absolute;
    top: 40px;
    width: 240px;
    fill: $text-colour;
}

#contact {
    position: absolute;
    bottom: 40px;
    width: 160px;
    text-align: center;
    cursor: pointer;
    z-index: 1;

    svg {
        transition: 0.25s ease;
    }

    svg:hover {
        fill: $hover-colour;
    }
}

.svg-canvas {
    position: absolute;
    pointer-events: none;
}

.click-capture {
    fill: none;
    pointer-events: visible;
    cursor: pointer;
    stroke: $border-colour;
    stroke-width: $outline-thickness;
    stroke-linejoin: round;
    //filter: drop-shadow(2px 4px 6px black);
    width: 500px;
}

.content-page {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    color: $text-colour;
    font-weight: $text-weight;
    background-color: $background-colour;
    box-sizing: border-box;


    scrollbar-width: none;
    -ms-overflow-style: none;
    
    &::-webkit-scrollbar {
        display: none;
    }

    &.experience {
        clip-path: url(#clip-top);

        .page-title {
            width: 150px;
            padding-top: 10px;
        }
    }

    &.about {
        clip-path: url(#clip-middle);

        .page-title {
            width: 90px;
        }
    }

    &.projects {
        clip-path: url(#clip-bottom);

        .page-title {
            width: 125px;
        }
    }

    .page-title {
        position: absolute;
        right: calc($outline-thickness * 2);

        .written {
            filter: invert(1);
            mix-blend-mode: difference;
        }
    }

    .content {
        margin-top: 60px;
        padding: 20px;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 681px) {
            flex-direction: row;
        }
    }

    .content-grid {
        display: grid;
        margin-top: calc(60px - $outline-thickness);
        grid-template-columns: 1fr;

        @media screen and (max-width: 360px) {
            
        }
    
        @media screen and (min-width: 361px) and (max-width: 680px) {
            
        }
    
        @media screen and (min-width: 681px) {
            grid-template-columns: 1fr 1fr;
        }

    }

    .project {

        &.text {
            padding: 20px;
            border-top: $outline-thickness solid black;
        }

        @media screen and (min-width: 681px) {
            &.left.description {
                grid-column: 1;
                grid-row: 1;
            }
            &.left.photos {
                grid-column: 2;
                grid-row: 1;
            }
            &.right.description {
                grid-column: 2;
                grid-row: 2;
            }
            &.right.photos {
                grid-column: 1;
                grid-row: 2;
            }
        }

        .project-title {
            margin-bottom: 20px;
        }

        .project-entity {
            font-weight: 700;
        }

        .project-location {
            font-weight: 300;
        }

        .project-year {
            font-size: 16px;
            font-style: italic;
            margin-bottom: 25px;
        }

        .project-description {
            font-size: 14px;

            a {
                transition: 0.25s ease;

                &:visited {
                    color: $text-colour;
                }

                &:hover {
                    color: $hover-colour;
                }
            }
        }

        .photo {
            cursor: pointer;
            border-top: $outline-thickness solid $border-colour;

            @media screen and (max-width: 680px) {
                display: none;
            }
        }

        .photo:last-child {
            margin-bottom: -4px;
        }

        .photo img,video {
            display: block;
            width: 100%;
            object-fit: contain;
        }

        .yarl__root {
            cursor: pointer;

            @media screen and (min-width: 681px) {
                display: none;
            }
        }


    }

    .page-header {
        position: fixed;
        z-index: 1;
        display: flex;
        align-items: center;
        //justify-content: center;
        height: 60px;
        width: calc(100% - $side-margins);
        min-width: calc(360px - $side-margins);
        //left: 0px;
        outline: $outline-thickness solid $border-colour;
        outline-offset: -$outline-thickness;
        background: $menu-colour;
    
        @media screen and (min-width: 980px) {
            width: 960px;
        }

    }

    .page-scroller {
        position: fixed;
        height: 30px;
        width: 100%;
        min-width: 360px;
        bottom: 30px;

        .contact {
            display: flex;
            justify-content: center;
            position: absolute;
            top: 3px;
            width: calc(100% - $side-margins);
            filter: invert(1);
            mix-blend-mode: difference;
        }
    }

    .scrollbar {
        background-color: $border-colour;
        margin-left: $outline-thickness;
        height: 100%;
        width: 0px;
    }


    .exp-card {
        margin: 10px 20px 40px 20px;
    }

    .exp-header {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 425px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;

            .exp-details {
                align-items: center;
            }

            .exp-title {
                text-align: center;
            }
        }
    }

    .exp-details {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .exp-time {
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
        }

        .exp-title {
            font-size: 20px;
            font-weight: 800;
            margin: 5px 0px;
        }

        .exp-location {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;

            div {
                margin-right: 20px;
            }
        }

    }

    .exp-logo {
        margin: 10px;
        img {
            width: 100px;
        }
    }

    .exp-body {
        font-size: 15px;
        font-weight: 400;

        ul {
            padding-inline-start: 20px;
        }

        li {
            margin: 10px 0px;
        }
    }

    #headshot-wrapper {
        display: flex;
        justify-content: center;

        @media screen and (min-width: 681px) {
            flex-direction: column;
        }
    }

    #headshot {
        width: 300px;
        height: 300px;
        border: 3px solid black;
    }

    #bio-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    #bio {
        
        display: grid;
        justify-items: center;
        max-width: 600px;

        ul {
            padding-inline-start: 10px;
        }

        li {
            margin-bottom: 10px;
        }

        @media screen and (min-width: 681px) {
            margin: 10px 45px;
        }
    }

    .line-break {
        width: 100%;
        height: $outline-thickness;
        background-color: black;
        margin: 25px 0;
    }

/* to be deleted */
    #links-wrapper {
        display: grid;
        width: 100%;
        justify-items: center;
    }

    #links {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;

        a {
            text-decoration: none;
            color: inherit;
        }

        .link {
            cursor: pointer;
            width: fit-content;
            padding-bottom: 1px;
            border-bottom: 3px solid $border-colour;
            transition: 0.25s ease;
        }

        .link:hover {
            border-bottom: 3px solid $hover-colour;
        }
    }
/* */
}

.close-button {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 60px;
    height: 60px;
    cursor: pointer;
    filter: invert(1);
    mix-blend-mode: difference;
}

.menu-option-cover {
    position: absolute;
    width: 100%;
    height: 100%;

    &.experience {
        clip-path: url(#clip-top);
    }

    &.about {
        clip-path: url(#clip-middle);
    }

    &.projects {
        clip-path: url(#clip-bottom);
    }

}

#menu-cover-top {

    & .menu-text-wrapper {
        
        @media screen and (max-width: 360px) {
            top: 116px;
        }
    
        @media screen and (min-width: 361px) and (max-width: 680px) {
            top: 106px;
        }
    
        @media screen and (min-width: 681px) {
            top: 96px;
        }
    }

}

#menu-cover-middle {

    & .menu-text-wrapper {
        @media screen and (max-width: 360px) {
            top: 287px;
        }

        @media screen and (min-width: 361px) and (max-width: 680px) {
            top: 277px;
        }

        @media screen and (min-width: 681px) {
            top: 267px;
        }
    }
    
}

#menu-cover-bottom {

    & .menu-text-wrapper {
        @media screen and (max-width: 360px) {
            top: 416px;
        }

        @media screen and (min-width: 361px) and (max-width: 680px) {
            top: 406px;
        }

        @media screen and (min-width: 681px) {
            top: 396px;
        }
    }
    
}

.menu-text-wrapper {
    position: relative;

    background-color: $menu-colour;
    border-bottom: $outline-thickness solid $border-colour;
    font-size: 22px;
}

.menu-text-line {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 32px;
}

.written-wrapper {
    @extend %container-for-centering;
    position: relative;
    flex-shrink: 0;

    &.experience {
        width: 160px;;
    }

    &.about {
        width: 100px;
    }

    &.projects {
        width: 135px;
    }
}

.written {
    fill: $text-colour;

    &.contact {
        width: 75%;
        margin-right: 5%;
    }

    &.envelope {
        width: 20%;
    }

    &.experience {
        //max-width: 150px;
        width: 150px;
    }

    &.about {
        //max-width: 90px;
        width: 90px;
    }

    &.projects {
        //max-width: 125px;
        width: 125px;
    }

    &.x {
        //max-width: 35px;
        width: 35px;
        transition: 0.25s ease;
    }

    &.x:hover {
        fill: $hover-colour;
    }

    &.ecom {
        width: 200px;
        margin-right: 15px;
    }

    &.developer {
        width: 300px;
        margin-bottom: -4px;
    }

    &.bachelor {
        width: 175px;
        margin-right: 15px;
    }

    &.of {
        width: 45px;
        margin-right: 15px;
        margin-bottom: 3px;
    }

    &.compsci {
        width: 300px;
        margin-bottom: -5px;
    }

    &.stage {
        width: 250px;
        margin-right: 20px;
    }

    &.fleece {
        width: 150px;
    }

    &.ephemeris {
        width: 180px;
    }
}