@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap");
/*$background-colour: #25072C;
$menu-colour: #4A0E58;
$text-colour: white;*/
/*** display breakpoints ***

phone-small : 
@media screen and (max-width: 360px)

phone-regular : 
@media screen and (min-width: 361px) and (max-width: 640px)

tablet and up : 
@media screen and (min-width: 641px)

tablet : 
@media screen and (min-width: 641px) and (max-width: 999px)

pocket : 
@media screen and (max-width: 999px)

    @media screen and (max-width: 360px) {

    }

    @media screen and (min-width: 361px) and (max-width: 740px) {

    }

    @media screen and (min-width: 741px) {

    }

*/
.written-wrapper, #app-frame, #app-background {
  display: flex;
  align-items: center;
  justify-content: center;
}

#app-frame {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

body {
  background-color: #f5f3f0;
}

#app-background {
  width: 100vw;
  height: 100vh;
  min-height: 680px;
  min-width: 360px;
  font-family: system-ui;
  background-image: url("dot.svg");
  background-repeat: repeat;
}

#app-frame {
  position: relative;
  visibility: hidden;
  overflow: hidden;
  max-height: 640px;
}
@media screen and (max-width: 680px) {
  #app-frame {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }
}
@media screen and (min-width: 681px) and (max-width: 979px) {
  #app-frame {
    width: calc(100% - 20px);
    height: calc(100% - 40px);
  }
}
@media screen and (min-width: 980px) {
  #app-frame {
    width: 960px;
    height: calc(100% - 40px);
  }
}

#signature {
  position: absolute;
  top: 40px;
  width: 240px;
  fill: black;
}

#contact {
  position: absolute;
  bottom: 40px;
  width: 160px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
}
#contact svg {
  transition: 0.25s ease;
}
#contact svg:hover {
  fill: red;
}

.svg-canvas {
  position: absolute;
  pointer-events: none;
}

.click-capture {
  fill: none;
  pointer-events: visible;
  cursor: pointer;
  stroke: black;
  stroke-width: 6px;
  stroke-linejoin: round;
  width: 500px;
}

.content-page {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  color: black;
  font-weight: 600;
  background-color: #f5f3f0;
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* to be deleted */
  /* */
}
.content-page::-webkit-scrollbar {
  display: none;
}
.content-page.experience {
  clip-path: url(#clip-top);
}
.content-page.experience .page-title {
  width: 150px;
  padding-top: 10px;
}
.content-page.about {
  clip-path: url(#clip-middle);
}
.content-page.about .page-title {
  width: 90px;
}
.content-page.projects {
  clip-path: url(#clip-bottom);
}
.content-page.projects .page-title {
  width: 125px;
}
.content-page .page-title {
  position: absolute;
  right: 12px;
}
.content-page .page-title .written {
  filter: invert(1);
  mix-blend-mode: difference;
}
.content-page .content {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 681px) {
  .content-page .content {
    flex-direction: row;
  }
}
.content-page .content-grid {
  display: grid;
  margin-top: 54px;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 681px) {
  .content-page .content-grid {
    grid-template-columns: 1fr 1fr;
  }
}
.content-page .project.text {
  padding: 20px;
  border-top: 6px solid black;
}
@media screen and (min-width: 681px) {
  .content-page .project.left.description {
    grid-column: 1;
    grid-row: 1;
  }
  .content-page .project.left.photos {
    grid-column: 2;
    grid-row: 1;
  }
  .content-page .project.right.description {
    grid-column: 2;
    grid-row: 2;
  }
  .content-page .project.right.photos {
    grid-column: 1;
    grid-row: 2;
  }
}
.content-page .project .project-title {
  margin-bottom: 20px;
}
.content-page .project .project-entity {
  font-weight: 700;
}
.content-page .project .project-location {
  font-weight: 300;
}
.content-page .project .project-year {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 25px;
}
.content-page .project .project-description {
  font-size: 14px;
}
.content-page .project .project-description a {
  transition: 0.25s ease;
}
.content-page .project .project-description a:visited {
  color: black;
}
.content-page .project .project-description a:hover {
  color: red;
}
.content-page .project .photo {
  cursor: pointer;
  border-top: 6px solid black;
}
@media screen and (max-width: 680px) {
  .content-page .project .photo {
    display: none;
  }
}
.content-page .project .photo:last-child {
  margin-bottom: -4px;
}
.content-page .project .photo img, .content-page .project video {
  display: block;
  width: 100%;
  object-fit: contain;
}
.content-page .project .yarl__root {
  cursor: pointer;
}
@media screen and (min-width: 681px) {
  .content-page .project .yarl__root {
    display: none;
  }
}
.content-page .page-header {
  position: fixed;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 60px;
  width: calc(100% - 20px);
  min-width: 340px;
  outline: 6px solid black;
  outline-offset: -6px;
  background: #edeae4;
}
@media screen and (min-width: 980px) {
  .content-page .page-header {
    width: 960px;
  }
}
.content-page .page-scroller {
  position: fixed;
  height: 30px;
  width: 100%;
  min-width: 360px;
  bottom: 30px;
}
.content-page .page-scroller .contact {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 3px;
  width: calc(100% - 20px);
  filter: invert(1);
  mix-blend-mode: difference;
}
.content-page .scrollbar {
  background-color: black;
  margin-left: 6px;
  height: 100%;
  width: 0px;
}
.content-page .exp-card {
  margin: 10px 20px 40px 20px;
}
.content-page .exp-header {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 425px) {
  .content-page .exp-header {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .content-page .exp-header .exp-details {
    align-items: center;
  }
  .content-page .exp-header .exp-title {
    text-align: center;
  }
}
.content-page .exp-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content-page .exp-details .exp-time {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}
.content-page .exp-details .exp-title {
  font-size: 20px;
  font-weight: 800;
  margin: 5px 0px;
}
.content-page .exp-details .exp-location {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
}
.content-page .exp-details .exp-location div {
  margin-right: 20px;
}
.content-page .exp-logo {
  margin: 10px;
}
.content-page .exp-logo img {
  width: 100px;
}
.content-page .exp-body {
  font-size: 15px;
  font-weight: 400;
}
.content-page .exp-body ul {
  padding-inline-start: 20px;
}
.content-page .exp-body li {
  margin: 10px 0px;
}
.content-page #headshot-wrapper {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 681px) {
  .content-page #headshot-wrapper {
    flex-direction: column;
  }
}
.content-page #headshot {
  width: 300px;
  height: 300px;
  border: 3px solid black;
}
.content-page #bio-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.content-page #bio {
  display: grid;
  justify-items: center;
  max-width: 600px;
}
.content-page #bio ul {
  padding-inline-start: 10px;
}
.content-page #bio li {
  margin-bottom: 10px;
}
@media screen and (min-width: 681px) {
  .content-page #bio {
    margin: 10px 45px;
  }
}
.content-page .line-break {
  width: 100%;
  height: 6px;
  background-color: black;
  margin: 25px 0;
}
.content-page #links-wrapper {
  display: grid;
  width: 100%;
  justify-items: center;
}
.content-page #links {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}
.content-page #links a {
  text-decoration: none;
  color: inherit;
}
.content-page #links .link {
  cursor: pointer;
  width: fit-content;
  padding-bottom: 1px;
  border-bottom: 3px solid black;
  transition: 0.25s ease;
}
.content-page #links .link:hover {
  border-bottom: 3px solid red;
}

.close-button {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 60px;
  height: 60px;
  cursor: pointer;
  filter: invert(1);
  mix-blend-mode: difference;
}

.menu-option-cover {
  position: absolute;
  width: 100%;
  height: 100%;
}
.menu-option-cover.experience {
  clip-path: url(#clip-top);
}
.menu-option-cover.about {
  clip-path: url(#clip-middle);
}
.menu-option-cover.projects {
  clip-path: url(#clip-bottom);
}

@media screen and (max-width: 360px) {
  #menu-cover-top .menu-text-wrapper {
    top: 116px;
  }
}
@media screen and (min-width: 361px) and (max-width: 680px) {
  #menu-cover-top .menu-text-wrapper {
    top: 106px;
  }
}
@media screen and (min-width: 681px) {
  #menu-cover-top .menu-text-wrapper {
    top: 96px;
  }
}

@media screen and (max-width: 360px) {
  #menu-cover-middle .menu-text-wrapper {
    top: 287px;
  }
}
@media screen and (min-width: 361px) and (max-width: 680px) {
  #menu-cover-middle .menu-text-wrapper {
    top: 277px;
  }
}
@media screen and (min-width: 681px) {
  #menu-cover-middle .menu-text-wrapper {
    top: 267px;
  }
}

@media screen and (max-width: 360px) {
  #menu-cover-bottom .menu-text-wrapper {
    top: 416px;
  }
}
@media screen and (min-width: 361px) and (max-width: 680px) {
  #menu-cover-bottom .menu-text-wrapper {
    top: 406px;
  }
}
@media screen and (min-width: 681px) {
  #menu-cover-bottom .menu-text-wrapper {
    top: 396px;
  }
}

.menu-text-wrapper {
  position: relative;
  background-color: #edeae4;
  border-bottom: 6px solid black;
  font-size: 22px;
}

.menu-text-line {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 32px;
}

.written-wrapper {
  position: relative;
  flex-shrink: 0;
}
.written-wrapper.experience {
  width: 160px;
}
.written-wrapper.about {
  width: 100px;
}
.written-wrapper.projects {
  width: 135px;
}

.written {
  fill: black;
}
.written.contact {
  width: 75%;
  margin-right: 5%;
}
.written.envelope {
  width: 20%;
}
.written.experience {
  width: 150px;
}
.written.about {
  width: 90px;
}
.written.projects {
  width: 125px;
}
.written.x {
  width: 35px;
  transition: 0.25s ease;
}
.written.x:hover {
  fill: red;
}
.written.ecom {
  width: 200px;
  margin-right: 15px;
}
.written.developer {
  width: 300px;
  margin-bottom: -4px;
}
.written.bachelor {
  width: 175px;
  margin-right: 15px;
}
.written.of {
  width: 45px;
  margin-right: 15px;
  margin-bottom: 3px;
}
.written.compsci {
  width: 300px;
  margin-bottom: -5px;
}
.written.stage {
  width: 250px;
  margin-right: 20px;
}
.written.fleece {
  width: 150px;
}
.written.ephemeris {
  width: 180px;
}

